const ejs = require('ejs')
const pjson = require('./package.json')

import './app/css/app.scss';

import $ from "jquery";
window.jQuery = $;
window.$ = $;

import store from "store";
window.store = store;

import * as noUiSlider from 'nouislider';
import Swal from 'sweetalert2';
window.dlySwal = Swal.mixin({
    customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
})

window.noUiSlider = noUiSlider;
window.Swal = Swal;

window._AppApi = require('/app/js/objs/api');
window._AppUser = require('/app/js/objs/user');

require("select2");
require("bootstrap");

require('/app/js/var');
require('/app/js/function');
require('/app/js/init');
require('/renderer');


global.pjson = pjson

