module.exports = function() {
	var api = {};

	api.settings = {
		apiUrl : 'https://dev.pxc-api-master.pxc-dev.de/v2/groupware/pxc/public/api/v1',
		//apiUrl : 'http://pxc-api.local/v2/groupware/pxc/public/api/v1',
		//apiUrl : 'http://localhost/pxc/public/api/v1'
	};

	api.init = function() {};

	api.put = function(table,data) {
		var url = this.settings.apiUrl + '/' + table;

		var returnData = {};
		$.ajax({
			'async' : false,
			'type' : 'PUT',
			'dataType' : 'json',
			'url' : url,
			'data': data,
			'success' : function(data) {
				returnData = data;
			}
		});
		return returnData;
	}

	api.post = function(table,data, additional) {
		var url = this.settings.apiUrl + '/' + table;

		if (additional) {
			url = url + additional;
		}

		var returnData = {};
		$.ajax({
			'async' : false,
			'type' : 'POST',
			'dataType' : 'json',
			'url' : url,
			'data': data,
			'success' : function(data) {
				returnData = data;
			}
		});
		return returnData;
	}

	api.call = function(table,id,additional) {
		var url = this.settings.apiUrl + '/' + table;
		if (id && id != false && id != 'false') {
			url = url + '/' + id;
		}
		if (additional) {
			url = url + additional;
		}
		var returnData = {};
		$.ajax({
			'async' : false,
			'type' : 'GET',
			'dataType' : 'json',
			'url' : url,
			'success' : function(data) {
				returnData = data;
			}
		});
		return returnData;

	};

	api.init();
	return api;
}
