const ejs = require('ejs');
const user = {};

require('/app/js/objs/api');

const defaultConfiguration = {
	'notification-timer': 1,
	'remove-saved-entries': 0,
	'show-abrechnungstyp': 0,
	'show-time': false,
	'dayhours': 8,
	'prefill-recall': 0,
	'prefill-recall-directstart': 0
};



if(!store.get('configuration')) {
	store.set('configuration', defaultConfiguration)
}
if(!store.get('timesheets')) {
	store.set('timesheets', {})
}

window._today = new Date();
window.setInterval(function(){
	let date = new Date();
	if (date.getDay() != window._today.getDay()) {
		// neuer Tag. reload
		location.reload();
	}
}, 10000);
window.setTimeout(function(){
	// bei den aktuellen einmal das datum setzen
	window._date = new Date();
	let timestamp = Math.ceil(window._date.getTime() / 1000);
	$('.zeiterfassung-timestamp').val(timestamp);
},500);

window._configuration = store.get('configuration') || {};
window._stundenzettel = store.get('timesheets') || {};

window.AppApi = new _AppApi();
window.SwornApi = new _AppApi();
window.SwornApi.settings.apiUrl = 'https://dev.pxc-api-master.pxc-dev.de/v1/swornpro';
//SwornApi.settings.apiUrl = 'https://pxc-api.ddev.site/v1/swornpro';
window.AppUser = new _AppUser();
if (AppUser && AppUser.data && AppUser.data.id) {
	$.ajax({
		url: '/getTimesheets.php',
		type: 'post',
		async: false,
		'dataType' : 'json',
		'data': {userid: AppUser.data.id},
		success: function(data){
			if (data) {
				// online data einmalig in den store schreiben
				window._stundenzettel = data;
				store.set('timesheets', data);

			}
		}
	});
	// gespeicherte Stundenzettel fuer heute auslesen
	//window._savedStundenzettel = SwornApi.call('timesheets', AppUser.data.id);
}

window.AddressbookData = false;

window.IntUsers = false;
