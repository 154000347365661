module.exports = function() {
	var user = {};
	var md5 = require('md5');

	user.settings = {
		loginUrl : 'https://dev.pxc-api-master.pxc-dev.de/v1/swornpro/users/',
		//loginUrl : 'http://pxc-api.local/v1/groupware/pxc/public/api/v1',
		//loginUrl : 'http://pxc-api.local/v1/swornpro/users/',
	};
	user.loginState = false;
	user.data = {};


	user.init = function() {
		// ist der user schon eingeloggt?
		if (window.store.get('user') && window.store.get('user').id) {
			user.data = window.store.get('user');
			user.loginState = true;
		} else {
			$.ajax({
				'async' : false,
				'type' : 'GET',
				'xhrFields': { withCredentials: true },
				'crossDomain': true,
				'dataType' : 'json',
				'url' : this.settings.loginUrl + 'loginStatus',
				'success' : function(data) {
					if (data.loginStatus && data.loginStatus == 1) {
						user.data = data.loginUser;
						user.loginState = true;
					} else {
						$('#errorMessage').html('<p>Benutzername oder Passwort falsch!</p>');
					}
				}
			});
		}
	};

	user.isLoggedIn = function() {
		return this.loginState;
	}

	user.login = function(username,password) {
		$.ajax(this.settings.loginUrl + 'auth', {
			method: 'POST',
			data: {username: username, password: password},
			crossDomain: true,
			success: function(data) {
				console.log(data);
				if (data.error) {
					$('#errorMessage').html('<p>Benutzername oder Passwort falsch!</p>');
					return false;
				}
				if (data.loginStatus && data.loginStatus == 1) {
					window.store.set('user', data.loginUser);
					//window.location.hash = '#home';
					window.location.reload();

				}

			}
		});

	};

	user.logout = function() {
		$.get(this.settings.loginUrl + 'logout', function(data){
			if (data) {
				window.location.reload();
			}
		});
		window.store.remove('user');
	}


	user.init();
	return user;
}
